import router from "./router/index";
import store from '@/store'
import { getModule } from "vuex-module-decorators";
import LoginStore from "./store/modules/login";
import Breadcrumb from "./store/modules/breadcrumb";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();



type Token = string | null | undefined

/**
 * 路由前置钩子函数，可以做点什么
 */
router.beforeEach(async function (to, form, next) {
  const breadcrumb = getModule(Breadcrumb, store)
  breadcrumb.setMatched(to.matched)
  const token: Token = cookies.get('TOKEN_ACCOUNT')
  if(token) {
    const {currentUser} = store.getters
    if(!currentUser) {
      const loginStore = getModule(LoginStore, store)
      const userInfo:any = await loginStore.getCurrentUser()
      const {menus}=userInfo
      router.addRoute(menus?menus:[])
      next()
    }else{
      next();
    }
  }else{
    if(to.path !== '/login') {
      next({path: '/login'});
    }else{
      next()
    }
  }
});
