import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import { login, getCurrentUser } from "@/api/login";
import { LoginForm } from "@/ts-type/login";
import { AxiosRequestConfig } from "axios";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

@Module({ namespaced: true, name: "login", store: store })
export default class LoginStore extends VuexModule {
  token: string | null = cookies.get('TOKEN_ACCOUNT');
  currentUser: any = null;
  userPermission: [] = [];

  /**
   * 函数被Mutation修饰符装饰后，函数内this指向当前state
   */
  @Mutation
  setCurrentUser(currentUser: any) {
    this.currentUser = currentUser;
  }

  @Mutation
  setUserPermission(userPermission: []) {
    this.userPermission = userPermission;
  }

  @Mutation
  setToken(token: string | null) {
    this.token = token;
  }

  /**
   * 用@Action装饰后，在函数内commit时, 只需调用this.context.commit（'sss'，params）
   */
  @Action({ rawError: true })
  login(loginPayload: AxiosRequestConfig<LoginForm>) {
    return new Promise((resolve, reject) => {
      login(loginPayload)
        .then((token: any) => {
          this.context.commit("setToken", token.data.tokenValue);
          cookies.set("TOKEN_ACCOUNT", token.data.tokenValue);
          this.context.dispatch('getCurrentUser').then((userInfo) => {
            resolve(userInfo.data);
          })
        })
        .catch(() => reject());
    });
  }

  @Action({ rawError: true })
  getCurrentUser() {
    return new Promise((resolve, reject) => {
      getCurrentUser()
        .then((userInfo: any) => {
          this.context.commit("setCurrentUser", userInfo.data);
          this.context.commit("setUserPermission", userInfo.data.userPermission);
          resolve(userInfo.data);
        })
        .catch(() => reject());
    });
  }

  @Action({ rawError: true })
  layout() {
    return new Promise((resolve,reject) => {
      cookies.set("TOKEN_ACCOUNT", '');
      cookies.set("phone", '');
      cookies.set("password", '');
      this.context.commit("setToken", '');
      this.context.commit("setCurrentUser", null);
      this.context.commit("setUserPermission", []);
      resolve('')
    })
  }
}
