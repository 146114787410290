import JSEncrypt from 'jsencrypt'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDjtDXGpSWT9bZgoPcyRm+vd9ayJOb1HPu97fzoRXqosLn/RNp12AxKEUl9B9OHSctzGShvXEFb0BYNdInrqGzOMuLW9/ElAWNfJYyrF16AW1GJwVLO90cMcHXMV7lHZGqZMfylql6tJTykkdKR/0nN4PBKWomK/N5gfU3FhfwzZQIDAQAB'

// 加密
export function encrypt(txt:any) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}

